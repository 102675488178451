import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { api_url as api } from '../Settings';
import Store from '../Redux/Store';
import { DisconnectNameAction } from '../Redux/Actions/HeaderActions';

interface getConfig {
    params?: object
}

export type Token = string;

/**
 * Wrapper of axios
 * Used for JWT required endpoints
 */
class ConnectedRequest {

    public isConnected: boolean = false;
    /**
     * Get the access token
     * @returns The access token or null
     */
    public getAccess(): Token | null {
        let access = localStorage.getItem("access_token");
        if (access)
            return access;
        return null;
    }

    /**
     * Get the refresh token
     * @return The refresh token
     */
    public getRefresh(): Token | null {
        let access = localStorage.getItem("refresh_token");
        if (access)
            return access;
        return null;
    }

    /**
     * Set the access token
     * @param token The access token
     */
    public setAccess(token: Token): void {
        localStorage.setItem("access_token", token);
    }

    /**
     * Set the refresh token
     * @param token The refresh token
     */
    public setRefresh(token: Token) {
        localStorage.setItem("refresh_token", token);
    }

    /**
     * Check if the client is logged
     * @returns The client is connected
     */
    private checkConnected(): boolean {
        if (!this.isConnected || this.getAccess() === null || this.getRefresh === null) {
            this.isConnected = false;
            return false;
        }
        return true;
    }

    /**
     * Axios get wrapper
     * @param endpoint Where to send the request
     * @param config Axios configuration
     * @returns The Axios Response or undefined if error
     */
    public async get(endpoint: string, config?: getConfig): Promise<AxiosResponse | undefined> {

        if (!this.checkConnected()) {
            this.handleDisconnected();
        }

        let instance = axios.create({
            baseURL: api,
            headers: {
                Authorization: 'Bearer ' + this.getAccess(),
                'Content-type': "application/json",
            },
        });

        let data;
        try {
            data = await instance.get(endpoint, config);
        } catch (error) {
            await this.handleRefresh();
            let instance = axios.create({
                baseURL: api,
                headers: {
                    Authorization: 'Bearer ' + this.getAccess(),
                    'Content-type': "application/json",
                },
            });
            try {
                data = await instance.get(endpoint, config);
            }
            catch {
                return undefined;
            }
        }
        return data;
    }

    /**
     * Axios post wrapper
     * @param endpoint Where to send the request
     * @param payload Payload of the post
     * @param config Axios configuration
     */
    public async post(endpoint: string, payload?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse | undefined> {

        if (!this.checkConnected()) {
            this.handleDisconnected();
        }

        let instance = axios.create({
            baseURL: api,
            headers: {
                Authorization: 'Bearer ' + this.getAccess(),
                'Content-type': "application/json",
            },
        });

        let data;
        try {
            data = await instance.post(endpoint, payload, config);
        } catch (error) {
            await this.handleRefresh();
            let instance = axios.create({
                baseURL: api,
                headers: {
                    Authorization: 'Bearer ' + this.getAccess(),
                    'Content-type': "application/json",
                },
            });
            try {
                data = await instance.post(endpoint, payload, config);
            }
            catch {
                return undefined;
            }
        }
        return data;
    }

    /**
     * Axios put wrapper
     * @param endpoint Where to send the request
     * @param payload Payload of the put
     * @param config Axios configuration
     */
    public async put(endpoint: string, payload?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse | undefined> {

        if (!this.checkConnected()) {
            this.handleDisconnected();
        }

        let instance = axios.create({
            baseURL: api,
            headers: {
                Authorization: 'Bearer ' + this.getAccess(),
                'Content-type': "application/json",
            },
        });

        let data;
        try {
            data = await instance.put(endpoint, payload, config);
        } catch (error) {
            await this.handleRefresh();
            let instance = axios.create({
                baseURL: api,
                headers: {
                    Authorization: 'Bearer ' + this.getAccess(),
                    'Content-type': "application/json",
                },
            });
            try {
                data = await instance.put(endpoint, payload, config);
            }
            catch {
                return undefined;
            }
        }
        return data;
    }

    /**
     * Refresh the Access token
     */
    public async handleRefresh(): Promise<void> {
        try {
            let data = await axios.post(api + "/jwt/token/refresh/", {
                refresh: this.getRefresh()
            });
            this.setAccess(data.data.access);
        } catch (err) {
            if (err.response && err.response.status && [401, 403].indexOf(err.response.status) !== -1) {
                this.handleDisconnected();
            }
            throw err;
        }
    }

    /**
     * Handle loggout if invalid access / refresh token
     */
    public handleDisconnected(): void {
        this.isConnected = false;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        Store.dispatch(DisconnectNameAction());
        throw Error("Not connected");
    }

}

export default new ConnectedRequest();