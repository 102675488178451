import React from 'react';
import { Container } from 'react-bootstrap'
import * as FooterModel from '../../Models/Footer';
import { SECOND_COLOR } from '../Consts';
import { Link } from 'react-router-dom';

const data : FooterModel.FooterModel = [
    {
        name: "Plan du site",
        links: [
            {
                name: "Accueil",
                link: "/"
            },
            {
                name: "Evénements",
                link: "/eventlist",
            },
            {
                name: "Mon Compte",
                link: "/profile",
            },
            {
                name: "Connexion",
                link: "/login",
            },
            {
                name: "Inscription",
                link: "/register"
            },
        ]
    },
    {
        name: "Evénements",
        links: [
            {
                name: "Gestion",
                link: "/admin",
            },
            {
                name: "Créer un événement",
                link: "/admin/create-event/",
            },
            {
                name: "Validation billet",
                link: "/admin/ticket-check",
            },
        ]
    },
    {
        name: "Contact",
        links: [],
    }
]

const styleTitle: React.CSSProperties = {
    color: SECOND_COLOR
}

export default class Footer extends React.PureComponent {
    render() {
        return <footer>
            <Container>
                {
                    data.map((elm: FooterModel.FooterColModel, key: number) =>
                        <div className="col-md-3 footerElm" key={key}>
                            <div className="titleFooter" style={styleTitle}>{elm.name}</div>
                            {elm.links.map((link: FooterModel.FooterElmModel, subkey: number) =>
                                {return link.external ?
                                <a href={link.link} target="_blank" rel="noopener noreferrer" key={subkey}>
                                    <div className="linkFooter">
                                        {link.name}
                                    </div>
                                </a> :
                                <Link to={link.link} key={subkey}>
                                    <div className="linkFooter">
                                        {link.name}
                                    </div>
                                </Link>
                                }
                            )}
                       </div>
                    )
                }
                <div className="FooterLogo">
                    <img src={process.env.PUBLIC_URL + "/images/ticket_logo_white.png"} alt="Ticket Logo" className="FooterLogoElm FooterLogoTicket" />
                    <img src={process.env.PUBLIC_URL + "/images/logo_epita.png"} alt="Epita Logo" className="FooterLogoElm FooterLogoEpita" />
                    <img src={process.env.PUBLIC_URL + "/images/neutrinos_logo_white.png"} alt="Neutrinos Logo" className="FooterLogoElm FooterLogoNeutrinos" />
                </div>
            </Container>
        </footer>
    }
}