import axios from 'axios';
import { api_url } from '../Settings';

interface argsRegister {
    nom: string,
    prenom: string,
    username: string,
    mail: string,
    birth: string,
    password: string,
    repassword: string,
}

class Register {

    /**
     * User signout handler
     * @param elements All fields required to login
     */
    public async register(elements: argsRegister): Promise<void> {
        // Check Name
        if (elements.nom.length < 1)
            throw Error("Nom Invalide")

        // Other checks

        //Check password
        if (elements.password !== elements.repassword)
            throw Error("Les mots de passes sont différents");

        if (elements.password.length < 6)
            throw Error("Le mot de passe est trop faible (au moins 6 charactères)");

        try {
            await axios.post(api_url + "/signup/", {
                email: elements.mail,
                first_name: elements.prenom,
                last_name: elements.nom,
                birth_date: elements.birth,
                username: elements.username,
                password: elements.password,
            });
        } catch (e) {
            if (e.response && e.response.data) {
                throw Error(Object.keys(e.response.data)[0] + ": " + Object.values(e.response.data)[0]);
            }
            throw e;
        }

    }

}

export default new Register();