import React from 'react';
import "./CardStructure.css";
import Arrow from './WhiteArrowRight.png';

interface props {
    name: string,
    mail: string,
}
export default class CardStructure extends React.Component<props> {

    render() {
        return <div className="CardStructure">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="ImgStructCard"/>
                        </td>
                        <td className="tdMidStructCard">
                            <div className="TitleStructCard">{this.props.name}</div>
                            <div className="MailStructCard">{this.props.mail}</div>
                        </td>
                        <td>
                            <img src={Arrow} alt="Go to structure" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}