import React from 'react';
import { EventModel } from '../../Models/Event';
import { connect } from 'react-redux';
import InvalidEvent from './InvalidEvent';
import Footer from '../Layers/Footer';
import Eventfetcher from '../../Utils/FetchEvents';
import Loader from '../Layers/Loader';
import FrontView from './FrontViewEventFocus';
import './EventFocus.css'
import Payement from './Payement';
import { Container } from 'react-bootstrap';
import Markdown from 'react-markdown';
import CardStructure from '../Layers/CardStructure';
import breaks from 'remark-breaks';

const defaultState: state = {
    invalid: false,
    event_id: 0,
    loading: true,
    event: null,
}

interface props{
    match?: {
        params?: {
            id?: string,
        }
    },
    events: EventModel[],
    user_id: number,
}

interface state {
    invalid: boolean,
    event_id: number,
    loading: boolean,
    event: EventModel | null,
}

class EventFocus extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.state = defaultState;
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    async componentWillReceiveProps(props: props): Promise<void> {
        if (!props.match || !props.match.params || !props.match.params.id) {
            this.setState({
                ...defaultState,
                invalid: true,
            });
            return;
        }
        let id = Number.parseInt(props.match.params.id, 10)
        if (Number.isNaN(id)) {
            this.setState({
                ...defaultState,
                invalid: true,
            });
            return;
        }

        let eventFocused: EventModel | undefined = props.events.find((elm: EventModel) => elm.id === id);
        if (!eventFocused) {
            this.setState({
                loading: true,
            });
            let res = await Eventfetcher.fetchId(id);
            if (res) {
                this.setState({
                    loading: false,
                    event_id: id,
                    event: res,
                });
                return;
            }
            else {
                this.setState({
                    ...defaultState,
                    invalid: true,
                })
                return;
            }
        }

        this.setState({
            invalid: false,
            event_id: id,
            loading: false,
            event: eventFocused,
        });
    }

    render() {
        if (this.state.invalid) {
            return <div>
                <InvalidEvent />
                <Footer />
            </div>
        }

        if (this.state.loading || this.state.event === null) {
            return <div style={{padding: "50px 0"}}><Loader timer={5000} errorMsg="Impossible de charger l'événement">Chargement de l'événement</Loader></div>
        }
        return <div id="EventFocus">
            <FrontView event={this.state.event}/>
            <Container>
                <div className="col-lg-10">
                    <Payement event={this.state.event} />
                    <div className="DescEvent">
                        <Markdown source={this.state.event.description} plugins={[breaks]}/>
                    </div>
                    <div className="StructureEventFocus">
                        <div className="TitleStructureEventFocus">
                            Organisateur
                        </div>
                        <CardStructure name={this.state.event.association} mail="test@test.com"/>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    }
}

function mapStateToProps(store: any) {
    return {
        events: store.events.events,
        user_id: store.header.id,
    }
}

export default connect(mapStateToProps)(EventFocus);