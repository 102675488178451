import React from 'react';
import './Register.css';
import BGimg from './BgRegister.png';
import Pannel from './Pannel'
import { Container } from 'react-bootstrap';

const styleBg: React.CSSProperties = {
    backgroundImage: "url('" + BGimg + "')",
}

interface props {

}

interface state {
    bgHeight: React.CSSProperties;
}

export default class Register extends React.Component<props, state> {


    constructor(props: props) {
        super(props);
        let allSize = window.innerHeight;
        let header = document.getElementById("header");
        if (header)
            allSize -= header.offsetHeight;
        this.state = {
            bgHeight: {
                minHeight: allSize,
            }
        };
        let headerFn = (): void => {
            let allSize = window.innerHeight;
            let header = document.getElementById("header");
            if (header)
                allSize -= header.offsetHeight;
            this.setState({
                bgHeight: {minHeight: allSize},
            });
        };
        setTimeout(headerFn, 200);
    }

    render() {
        return <div className="Register" style={{ ...styleBg, ...this.state.bgHeight }}>
            <div className="CenterRegister">
                <Container>
                    <Pannel />
                </Container>
            </div>
        </div>
    }
}