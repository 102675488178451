import React from 'react'
import { Loader as LoaderUI, Dimmer } from 'semantic-ui-react';

interface props {
    timer?: number,
    errorMsg?: string,
    className?: string,
}
interface state {
    show: boolean,
}

export default class Loader extends React.Component<props, state> {

    private customError: string;
    private timeout: NodeJS.Timeout | null = null;

    constructor(props: props) {
        super(props);
        this.state = {
            show: true,
        }

        if (props.errorMsg) {
            this.customError = props.errorMsg;
        }
        else {
            this.customError = "Can't load data";
        }

        if (props.timer) {
            this.timeout = setTimeout(() => {
                this.setState({
                    show: false,
                })
            }, props.timer);
        }


    }

    componentWillUnmount() {
        if (this.timeout)
            clearTimeout(this.timeout)
    }

    render() {
        return <div className={this.props.className}>
            {(this.state.show) ?
                <Dimmer active inverted style={{position: "relative", backgroundColor: "rgba(0, 0, 0, 0)"}}>
                    <LoaderUI active inverted>
                        {this.props.children}
                    </LoaderUI>
                </Dimmer>
                :
                <div>
                    {this.customError}
                </div>}
        </div>
    }
}