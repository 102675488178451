import { EventModel } from '../Models/Event';
import Moment from 'moment';

/**
 * Only get tomorow events
 * @param events All events
 * @returns Tomorow events
 */
export function GetTomorowEvents(events: EventModel[]): EventModel[] {
    let date = new Date();
    date.setHours(0, 0, 0);
    let dateMoment = Moment(date);

    const CheckTomorow = (event: EventModel): boolean => {
        let dateEventStart: Moment.Moment = Moment(event.dateTimeStart, "YYYY/MM/DD-hh:mm:ss");
        let dateEventEnd: Moment.Moment = Moment(event.dateTimeEnd, "YYYY/MM/DD-hh:mm:ss");
        if (!dateEventStart.isValid() || !dateEventEnd.isValid())
            return false;

        let cloneTomorow = dateMoment.clone().add(2, "days");
        let checkTomorowStart = dateEventStart.isBetween(dateMoment, cloneTomorow);

        let checkCurrent = dateEventStart.isBefore(dateMoment) && dateEventEnd.isAfter(dateMoment);

        return checkTomorowStart || checkCurrent;
    }
    return events.filter(CheckTomorow);
}

/**
 * All next events not in the Tomorow Event list
 * @param events All events
 */
export function GetNextEvents(events: EventModel[]): EventModel[] {
    let toRemove = GetTomorowEvents(events);
    return events.filter((elm) => toRemove.findIndex((que) => que.id === elm.id) === -1).slice(0, 5);
}