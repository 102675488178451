import redux from 'redux';
import { UserModel } from '../../Models/User';

export enum HeaderActions {
    UPDATE_NAME = 'UPDATE_NAME',
    DISCONNECT_NAME = "DISCONNECT_NAME",
    OFFLINE_SET = 'OFFLINE_SET',
}
export type ArgsUpdateName = UserModel;
export interface UpdateNamePayload extends redux.AnyAction {
    type: HeaderActions.UPDATE_NAME,
    name: string,
    email: string,
    firstname: string,
    lastname: string,
}

export interface DisconnectNamePayload extends redux.Action {
    type: HeaderActions,
}

export function UpdateNameAction(user: UserModel): UpdateNamePayload {
    return {
        type: HeaderActions.UPDATE_NAME,
        id: user.id,
        name: user.username,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
    }
}

export function DisconnectNameAction() : DisconnectNamePayload {
    return {
        type: HeaderActions.DISCONNECT_NAME,
    }
}


export const SetOfflineAction = (offline: boolean = true): redux.AnyAction => ({
    type: HeaderActions.OFFLINE_SET,
    payload: offline,
})