import { MONTHS } from '../Components/Consts';

/**
 * Custom date class
 */
export default class DateEvent {

    public Year: number = 0;
    public Month: number = 0;
    public Day: number = 0;
    public Hour: number = 0;
    public Minute: number = 0;
    public Second: number = 0;
    public Valid: boolean = true;
    public date: Date | null = null;

    /**
     * Go from django date to own custom date
     * @param date Django date
     */
    public static toModel(date: string): string {
        return date.split("-").join("/").replace("T", "-").replace("Z", "");
    }

    /**
     * @constructor
     * @param data Date to transform
     */
    constructor(data: string) {
        let separed: string[] = data.split("-");
        if (separed.length !== 2) {
            this.Valid = false;
            return;
        }
        let date = separed[0].split("/");
        let time = separed[1].split(":");
        if (date.length !== 3 || time.length !== 3) {
            this.Valid = false;
            return;
        }

        try {
            this.Year = parseInt(date[0]);
            this.Month = parseInt(date[1]);
            this.Day = parseInt(date[2]);
            this.Hour = parseInt(time[0]);
            this.Minute = parseInt(time[1]);
            this.Second = parseInt(time[2]);
        }
        catch {
            this.Valid = false;
            return;
        }
        if (this.Year < 1000 || this.Year >= 10000) {
            this.Valid = false;
            return;
        }
        this.date = new Date(this.Year, this.Month - 1, this.Day, this.Hour, this.Minute, this.Second);
    }

    /**
     * @returns Stringfied date
     */
    public DateToString(): string {
        if (!this.Valid)
            return "";
        let d: string = String(this.Day);
        if (this.Day < 10)
            d = "0" + d;

        let m: string = String(this.Month);
        if (this.Month < 10) {
            m = "0" + m;
        }
        return d + "/" + m;
    }

    /**
     * @returns ISO date string
     */
    public DateToStringIso(): string {
        if (!this.Valid)
            return "";
        let y: string = String(this.Year);
        while (y.length < 4) {
            y = '0' + y;
        }

        let d: string = String(this.Day);

        if (this.Day < 10)
            d = "0" + d;

        let m: string = String(this.Month);
        if (this.Month < 10) {
            m = "0" + m;
        }
        return y + '-' + m + '-' + d;
    }

    /**
     * Transform our custom date to django dates
     * @param date Own custom date
     * @returns Django date
     */
    public static toDjangoDate(date: string): string {
        let myDate = new DateEvent(date);
        let djangoDate = myDate.DateToStringIso();
        let djangoTime = myDate.TimeToString();
        return djangoDate + 'T' + djangoTime;
    }

    /**
     * Transform date to format like 18 Jan (if maxWidthMonth is set to 3)
     * @param maxWidthMonth The length of the month string
     */
    public DateToStringWithMonth(maxWidthMonth: number = 20): string {
        if (!this.Valid)
            return "";
        let d: string = String(this.Day);
        if (this.Day < 10)
            d = "0" + d;

        let m = MONTHS[this.Month - 1];

        m = m.substr(0, maxWidthMonth);

        return d + " " + m;
    }

    /**
     * Get thr time of our date
     */
    public TimeToString(): string {
        if (!this.Valid)
            return "";
        let h: string = String(this.Hour);
        if (this.Hour < 10)
            h = "0" + h;
        let m: string = String(this.Minute);
        if (this.Minute < 10)
            m = "0" + m;

        return h + ":" + m;
    }

    /**
     * Copoare two dates
     * @param other Other date to compare
     * @returns 0 if date are equals, -1 if this < other, 1 else
     */
    public compareTo(other: DateEvent): number {
        if (!this.Valid)
            return -1;
        if (!other.Valid)
            return 1;
        let thisDate = this.DateToString() + "-" + this.TimeToString();
        let OtherDate = other.DateToString() + "-" + this.TimeToString();
        if (OtherDate === thisDate)
            return 0;
        if (thisDate > OtherDate)
            return 1;
        return -1;
    }

    /**
     * Compare two dates
     * @param elm1 First date
     * @param elm2 Second date
     * @returns 0 if elm1 === elm2, -1 if elm1 < elm2, 1 else
     */
    public static compareTo(elm1: DateEvent, elm2: DateEvent): number {
        if (!elm1.Valid)
            return -1;
        if (!elm2.Valid)
            return 1;
        let thisDate = elm1.DateToString() + "-" + elm2.TimeToString();
        let OtherDate = elm1.DateToString() + "-" + elm2.TimeToString();
        if (OtherDate === thisDate)
            return 0;
        if (thisDate > OtherDate)
            return 1;
        return -1;
    }
}