import { TicketModel, fetchedTicket } from "../Models/Ticket";
import Store from '../Redux/Store';
import { FetchTickets } from '../Redux/Actions/TicketActions';
import cr from './ConnectedRequest';
import { api_url } from "../Settings";
import EventFetcher from './FetchEvents';
import { SetOfflineAction } from '../Redux/Actions/HeaderActions';

export interface ticketQR {
    id: number,
    code: string,
}

interface tmpTicket {
    id: number,
    event_id: number,
    code: string,
    user_id: number,
}

export default class TicketHelper {

    /**
     * Fetch tickets from server or localstorage
     */
    public static async FetchTickets(): Promise<void> {

        try {
            var data = await cr.get(api_url + "/ticket/jwt/user_list/");
        } catch {
            data = undefined;
        }
        let dataFetch: fetchedTicket;
        if (!data) {
            let tmpDataStorage = localStorage.getItem("ticket_offline")
            if (!tmpDataStorage) {
                dataFetch = [];
            } else {
                dataFetch = JSON.parse(tmpDataStorage);
            }
            Store.dispatch(SetOfflineAction());
        }
        else {
            dataFetch = data.data;
            localStorage.setItem("ticket_offline", JSON.stringify(dataFetch));
        }

        let dataTicket = dataFetch.map((elm): tmpTicket => ({
            code: elm.code_verification,
            event_id: elm.event_id,
            id: elm.id,
            user_id: elm.user_id,
        }));

        let state = Store.getState();
        if (!state.events.fetched) {
            await EventFetcher.fetch();
            state = Store.getState();
        }

        let events = state.events.events;

        let newDataFetch = dataTicket.map((elm): TicketModel => {
            let eventFocused = events.find((ev) => ev.id === elm.event_id);
            if (!eventFocused) {
                return {
                    ...elm,
                    datetime_start: "",
                    event_name: "Indéfini",
                    structure_name: "Indefini"
                }
            }
            return {
                ...elm,
                datetime_start: eventFocused.dateTimeStart,
                event_name: eventFocused.name,
                structure_name: eventFocused.association,
            }
        })


        Store.dispatch(FetchTickets(newDataFetch));
    }

    /**
     * Transform a ticket to a QRCode encodable data
     * @param ticket The ticket to parse
     * @returns The payload of the QRCode
     */
    public static GetQRCodeData(ticket: TicketModel): string {
        let data: ticketQR = {
            id: ticket.id,
            code: ticket.code,
        }
        return JSON.stringify(data)
    }

    /**
     * Parse a payload of QRCode into a ticketQR
     * @param code The payload of the QRCode
     * @returns The data parsed
     */
    public static GetDataFromQRCode(code: string): ticketQR | undefined {
        try {
            var data = JSON.parse(code);
        } catch {
            return undefined;
        }
        if (!data || !data.code || !data.id)
            return undefined;
        let ticketData: ticketQR = data;
        return ticketData;
    }
}