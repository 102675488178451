import Store from '../Redux/Store';
import Req from './ConnectedRequest';
import { UpdateNameAction } from '../Redux/Actions/HeaderActions';

/**
 * Semafore fom InitConnected
 */
let semafore: (Promise<void>)[] = [];

/**
 * Get user informations from server or localstorage if connected
 */
export default async function(): Promise<void> {
    if (semafore.length === 1) {
        await semafore[0];
        return;
    }
    let resolver: (() => void) = () => {};
    semafore.push(new Promise<void>((res) => {resolver = res}));
    let access = localStorage.getItem("access_token");
    let refresh = localStorage.getItem("refresh_token");
    if (!access || !refresh) {
        handleEndSemafore(resolver);
        return;
    }

    Req.isConnected = true;

    try {
        let tmp = await Req.get("/get_user_info/");
        if (!tmp) {
            handleEndSemafore(resolver);
            return;
        }
        var res = tmp.data;
        localStorage.setItem("save-header", JSON.stringify(res));
    } catch (e) {
        let data_save = localStorage.getItem("save-header");
        if (!data_save) {
            handleEndSemafore(resolver);
            return;
        }
        res = JSON.parse(data_save);
    }

    if (!res || !res.email) {
        handleEndSemafore(resolver);
        return;
    }

    Store.dispatch(UpdateNameAction(res))
    handleEndSemafore(resolver);
}

/**
 * Resolve semaphore
 * @param res The resolve function
 */
const handleEndSemafore = (res: () => void) => {
    res();
    semafore.pop();
}