import { EventModel } from '../Models/Event';
import DateEvent from './Date';
import Store from '../Redux/Store';
import { FetchEvent, SetEvent } from '../Redux/Actions/EventActions';
import { SetOfflineAction } from '../Redux/Actions/HeaderActions';
import { api_url } from '../Settings';
import axios from 'axios';

interface EventFetched {
    "id": number,
    "structure": {
        "id": number,
        "name": string,
        "mail": string,
        "website": string,
        "description": string,
        "type_id": number
    },
    "title": string,
    "description": string,
    "registration_start": string,
    "registration_end": string,
    "begin": string,
    "end": string,
    "extern_price": number,
    "intern_price": number,
    "wallpaper": string,
    "ticket_sold_extern": number,
    "ticket_sold_intern": number,
    "max_intern": number,
    "max_extern": number,
}


/**
 * Class to fetch events
 */
class FetchEvents {

    private static semafore: Promise<void>[] = [];

    /**
     * Go from fetched event to EventModel
     * @param event The event fetched
     * @return The event Model
     */
    private static FetchedToModel(event: EventFetched): EventModel {
        return {
            id: event.id,
            association: event.structure.name,
            dateTimeEnd: DateEvent.toModel(event.end),
            dateTimeStart: DateEvent.toModel(event.begin),
            description: event.description,
            imgUrl: event.wallpaper,
            name: event.title,
            externPrice: event.extern_price,
            internPrice: event.intern_price,
            externPlace: event.max_extern - event.ticket_sold_extern,
            internPlace: event.max_intern - event.ticket_sold_intern,
            association_id: event.structure.id,
        }
    }

    /**
     * Fetch events from the API or localstorage
     */
    public static async fetch() : Promise<void> {
        if (this.semafore.length !== 0) {
            await this.semafore[0];
            return;
        }
        let resolver = () => {};
        this.semafore.push(new Promise<void>((res) => {
            resolver = res;
        }))

        // Ordered Events
        try {
            var eventList = (await axios.get(api_url + "/event/")).data;
            localStorage.setItem("event-offline", JSON.stringify(eventList));
        }
        catch (e) {
            let event_save = localStorage.getItem("event-offline");
            if (!event_save) {
                eventList = [];
            } else {
                eventList = JSON.parse(event_save);
            }
            Store.dispatch(SetOfflineAction());
        }

        let elms: EventModel[] = (eventList as EventFetched[]).map(this.FetchedToModel);

        elms.sort((elm1: EventModel, elm2: EventModel) => {
            let date1 = new DateEvent(elm1.dateTimeStart);
            let date2 = new DateEvent(elm2.dateTimeStart);
            return date1.compareTo(date2);
        });

        // Remove past events
        let dateNow = new Date();
        elms = elms.filter((elm: EventModel) => {
            let dateEvent = new DateEvent(elm.dateTimeEnd);
            if (!dateEvent.Valid || !dateEvent.date)
                return false;
            return dateEvent.date >= dateNow;
        });

        Store.dispatch(FetchEvent(elms));
        resolver();
        this.semafore.pop();
    }

    /**
     * Fetch one event from the API or localstorage
     * @param id The id of the event
     */
    public static async fetchId(id: number) : Promise<EventModel | null> {

        try {
            var fetchedEvent = (await axios.get(api_url + "/event/" + id)).data;
        } catch(e) {
            let datas = localStorage.getItem("event-offline");
            Store.dispatch(SetOfflineAction());
            if (!datas)
                return null;
            fetchedEvent = JSON.parse(datas);
            if (!fetchedEvent)
                return null;
            fetchedEvent = (fetchedEvent as EventFetched[]).filter((elm) => elm.id === id);
        }

        let tmpFetched: EventFetched[] = (fetchedEvent as EventFetched[]);
        if (tmpFetched.length < 1)
            return null;
        let focused: EventModel = this.FetchedToModel(tmpFetched[0]);


        Store.dispatch(SetEvent(focused));
        return focused;

    }
}

export default FetchEvents;