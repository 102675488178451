import redux from 'redux';
import { EventModel } from '../../Models/Event';

export enum EventActions {
    FETCH_EVENTS = 'FETCH_EVENTS',
    SET_EVENT = 'SET_EVENT'
}
export type FetchEventArgAction = EventModel[];

export interface FetchPayloadAction extends redux.AnyAction {
    type: EventActions,
    payload: EventModel[],
}

export interface SetEventPayloadAction extends redux.AnyAction {
    type: EventActions,
    payload: EventModel,
}

export function FetchEvent(args: FetchEventArgAction) : FetchPayloadAction {
    return {
        type: EventActions.FETCH_EVENTS,
        payload: args,
    }
}

export function SetEvent(arg: EventModel) : SetEventPayloadAction {
    return {
        type: EventActions.SET_EVENT,
        payload: arg,
    }
}