import React from 'react';

interface props {
    onChange: Function,
    className?: string,
    children: any,
    typeField: string,
    target: string,
}

export default class Field extends React.Component<props> {

    constructor(props: props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        this.props.onChange(this.props.target, event.target.value)
    }

    render() {
        return <div className={"Field " + this.props.className}>
            <div className="InputName">{this.props.children}</div>
            <input type={this.props.typeField} className="InputField" onChange={this.handleChange}/>
        </div>
    }
}