import React from 'react';
import styles from "./Ticket.module.css";
import { TicketModel } from '../../Models/Ticket';
import { Tag } from 'antd';
import Date from '../MainPage/Minidate';
import { Link } from 'react-router-dom';

interface props {
    ticket: TicketModel,
}

const Ticket = ({ticket}: props) => {


    let time = "";
    try {
        let tmpDate = ticket.datetime_start.split("-")[1].split(":");
        time = tmpDate[0] + "h" + tmpDate[1];
    } catch {
    }

    return (
        <div className={styles.TicketElm}>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <div className={styles.PointTicket} />
                        </td>
                        <td className={styles.Elms}>
                            <div className={styles.EventName}>{ticket.event_name}</div>
                            <div><Tag color="red">{ticket.structure_name}</Tag></div>
                            <div className={styles.DateTimeContain}>
                                <Date date={ticket.datetime_start} className={styles.DateTicketElm} />
                                <div className={styles.TimeTicket}>
                                    {time}
                                </div>
                            </div>
                        </td>
                        <td className={styles.tdEventSee}>
                            <Link to={"/eventfocus/" + ticket.event_id}>
                                <div className={styles.ButtonSeeEvent}>Voir l'événement</div>
                            </Link>
                        </td>
                        <td className={styles.ButtonSeeTicket}>
                            <Link to={"/ticket/" + ticket.id}>
                            Ticket
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

}

export default Ticket;