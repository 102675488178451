import { EventSaveModel } from '../EventSaveModel';
import { AnyAction } from 'redux';

import { Actions as ActionEventCreator } from '../Actions/event_creator';
import moment from 'moment';

interface state {
    event_save: EventSaveModel | undefined,
}
let prevSavedEventStr = localStorage.getItem("saved_event");
let prevSavedEventMdl: EventSaveModel | undefined;
if (!prevSavedEventStr) {
    prevSavedEventMdl = undefined;
} else {
    try {
        prevSavedEventMdl = JSON.parse(prevSavedEventStr);
        if (prevSavedEventMdl) {
            prevSavedEventMdl.start = moment(prevSavedEventMdl.start)
            prevSavedEventMdl.end = moment(prevSavedEventMdl.end)
        }
    } catch (e) {
        prevSavedEventMdl = undefined;
    }
}
const defaultState: state = {
    event_save: prevSavedEventMdl,
}

export function UtilsReducer(state: state = defaultState, action: AnyAction): state {
    switch (action.type) {
        case ActionEventCreator.SAVE_EVENT: {
            localStorage.setItem("saved_event", JSON.stringify(action.payload))
            return {
                ...state,
                event_save: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}