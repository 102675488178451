import { EventAdminModel } from "../../../../Models/EventAdmin";
import Redux from 'redux';

export enum EventActions {
    FETCH = 'FETCH_EVENT_ADMIN',
};

export const FetchEventsAction = (payload: EventAdminModel[]): Redux.AnyAction => ({
    type: EventActions.FETCH,
    payload: payload,
})