import React from 'react';
import { connect } from 'react-redux';
import Logo from "./ticket_logo.png";
import { Link } from 'react-router-dom';
import { Popover, Icon, Button } from 'antd';
import LoginHelper from '../../Utils/Login';
import sh from "./HeaderMobile.module.css";
import Search from './Search';

interface props {
    isConnected: boolean,
    name: string,
}

interface state {
    mobile: boolean,
    showMenu: boolean,
    alreadyShown: boolean,
}

const commonLinks = [
    <Link to="/eventlist" key={1}>
        <div className="elm_nav_link">Evénements</div>
    </Link>
]

const Disconect = ({handleDisconect}: {handleDisconect: () => void}): JSX.Element => {

    return (
        <div>
            <Icon type="disconnect" style={{marginRight: 5, position: "relative", bottom: 3}}/>
            <span
                onClick={handleDisconect}
                style={{
                    color: "#1890ff",
                    cursor: "pointer",
                }}>Se déconnecter</span>
        </div>
    )
}

class Header extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            mobile: false,
            showMenu: false,
            alreadyShown: false,
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        if (window.outerWidth < 700 && !this.state.mobile) {
            this.setState({
                mobile: true,
            })
        }
        else if (window.outerWidth >= 700 && this.state.mobile) {
            this.setState({
                mobile: false,
            })
        };
    }

    handleDisconect = () => {
        LoginHelper.Diconnect();
    }

    handleShowMenu = () => {
        this.setState({
            showMenu: true,
            alreadyShown: true,
        })
    }

    handleHideMenu = () => {
        this.setState({
            showMenu: false,
        })
        setTimeout(() => {
            this.setState({
                alreadyShown: false,
            })
        }, 300);
    }

    render() {

    if (this.state.mobile) {

        let elmNav = (children: any) => <div className={sh.elmNav} onClick={this.handleHideMenu}>{children}</div>

        return (
            <>
                <div
                    className={((this.state.showMenu) ? sh.black + " " + sh.blackActive : ((this.state.alreadyShown) ? " " + sh.blackOut : sh.black))}
                    onClick={this.handleHideMenu}
                />
                <div className={sh.sideMenu + ((this.state.showMenu) ? (" " + sh.sideMenuActive) : "")}>
                    <div className={sh.titleMenu}>Menu</div>
                    {(this.props.isConnected && (
                        <>
                            <div className={sh.elmNav + " " + sh.nameConnected}>{this.props.name}</div>
                        </>
                    ))}
                    {
                        elmNav(
                            <Link to="/">
                                <div>
                                    <Icon type="home" className={sh.iconFix}/>
                                    Accueil
                                </div>
                            </Link>
                        )
                    }
                    {commonLinks.map((elm, index) => <React.Fragment key={index}>{elmNav(elm)}</React.Fragment>)}
                    {(!this.props.isConnected) && (
                        <>
                            {elmNav(
                                <Link to="/register">
                                    <div>
                                        <Icon type="user-add" className={sh.iconFix} />
                                        Inscription
                                </div>
                                </Link>
                            )}
                            {elmNav(
                                <Link to="/login">
                                    <div>
                                        <Icon type="login" className={sh.iconFix}/>
                                        Connexion
                                </div>
                                </Link>
                            )}
                        </>
                    )}
                    {(this.props.isConnected && (
                        <>
                            {elmNav(
                                <Disconect handleDisconect={this.handleDisconect} />
                            )}
                            {elmNav(
                                <Link to="/profile">
                                    <Button type="primary">
                                        Mon compte
                                    </Button>
                                </Link>
                            )}
                        </>
                    ))}

                </div>
                <div className={sh.fakeheader} />
                <header className={sh.header}>
                    <div className={sh.menu}>
                        <Icon type="menu" onClick={this.handleShowMenu}/>
                    </div>
                    <div className={sh.divlogo}>
                        <Link to="/">
                            <img src={Logo} className={sh.logo} alt="Tickets Logo" />
                        </Link>
                    </div>
                </header>
            </>
        )
    }

        return <header id="header">
            <nav className="container_fluid">
                <div className="elm_nav title col-lg-1 col-md-1 col-sm-2 col-2">
                    <Link to="/">
                        <img className="logo" src={Logo} alt="Tickets Logo" />
                    </Link>
                </div>
                <form className="elm_nav col-lg-7 col-md-5 d-none d-md-inline-block">
                    <Search className="search"/>
                </form>
                <div className="elm_nav left_align_nav col-lg-4 col-md-6 col-sm-10 col-10">
                    {(!this.props.isConnected) ?
                        <div>
                            {commonLinks.map((elm) => elm)}
                            <Link to="/register">
                                <div className="elm_nav_link">
                                    Inscription
                                </div>
                            </Link>
                            <Link to="/login">
                                <div className="elm_nav_link">
                                    Connexion
                                </div>
                            </Link>
                        </div>
                        : <div>
                            {commonLinks.map((elm) => elm)}
                            <div className="elm_nav_link" style={{cursor: "pointer"}}>
                                <Popover content={
                                    <>
                                        <Disconect handleDisconect={this.handleDisconect} />
                                    </>
                                }
                                placement="bottomRight"
                                >
                                    {this.props.name}
                                </Popover>
                            </div>
                            <div className="elm_nav_link">
                                <Link to="/profile">
                                    <Button type="primary">
                                    Mon compte
                                    </Button>
                                    </Link>
                            </div>
                        </div>
                    }
                </div>
            </nav>
        </header>
    }
}

function mapStateToProps(state: any) {
    return {
        isConnected: state.header.connected,
        name: state.header.name,
    }
}
export default connect(mapStateToProps)(Header);