import React from 'react';
import { DOMINANT_COLOR } from "../Consts";

interface props {
    width?: number,
    heigth?: number,
}

export default class Line extends React.Component<props> {
    render() {

        let style: React.CSSProperties = {
            borderRadius: 999,
            width: (this.props.width) ? this.props.width : 30,
            height: (this.props.heigth) ? this.props.heigth : 4,
            backgroundColor: DOMINANT_COLOR,
        }

        return <div style={style}/>
    }
}