import React, { useState, useEffect, useRef } from 'react';
import styles from "./Offline.module.css";
import { Icon } from 'antd';
import { connect } from 'react-redux';
import OfflineHelper from '../../Utils/OfflineHelper';

interface reduxProps {
    offline: boolean,
}

interface props extends reduxProps {
}

const Offline: React.FC<props> = (props: props) => {

    const [spin, setSpin] = useState<boolean>(false);
    const [showOffline, setShowOffline] = useState<boolean>(false);
    const refElm = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (props.offline) {
            setShowOffline(true);
        }
        else if (!props.offline && showOffline && refElm && refElm.current) {
            refElm.current.style.transform = "translateY(100%)";
            setTimeout(() => {
                setShowOffline(false);
            }, 300)
        }
    }, [props.offline, showOffline, refElm])

    const handleRefresh = () => {
        setSpin(true);
        OfflineHelper.GetAll()
            .then(() => {
                setSpin(false);
            })
    }

    return (
        <>
            {(showOffline) &&
                <div className={styles.Layer} ref={refElm}>
                    <div className={styles.circle} />
                    <div className={styles.msg}>Mode hors ligne</div>
                    <div className={styles.retry}>
                        <Icon type="sync" spin={spin} onClick={handleRefresh}/>
                    </div>
                </div>
            }
        </>
    )
};

const mapStateToProps = (state: any): reduxProps => ({
    offline: state.header.offline,
})


export default connect(mapStateToProps)(Offline);