import React, {useState, useEffect, useRef, useCallback} from 'react';
import SearchHelper from '../../Utils/SearchHelper';
import EventFetcher from '../../Utils/FetchEvents';
import { connect } from 'react-redux';
import { EventModel } from '../../Models/Event';
import styles from "./Search.module.css";
import MiniDate from '../MainPage/Minidate';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

interface ReduxPropsState {
    fetched: boolean,
    events: EventModel[],
}

interface askProps {
    className?: string,
}

interface props extends ReduxPropsState, askProps {
    history: any,
}

const Search: React.FC<props> = (props: props) => {

    const [search, setSearch] = useState<string>("");
    const [listSearch, setListSearch] = useState<EventModel[]>([]);

    useEffect(() => {
        if (search && search.trim() !== "" && !props.fetched) {
            EventFetcher.fetch();
        }
    }, [search, props.fetched]);

    const handleSearch = (payload: string) => {
        setSearch(payload);
    }

    const [focus , setFocus] = useState<boolean>(false);

    useEffect(() => {
        if (props.fetched && search) {
            setListSearch(SearchHelper.Search<EventModel>(props.events, search, ["name", "association"]));
            setFocus(true);
        }
    }, [props.fetched, props.events, search]);

    const handleClick = useCallback((id: number) => {
        setFocus(false);
        setSearch("");
        props.history.push("/eventfocus/" + id);
    }, [props.history]);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current)
            return;
        let inputRefCleanup = inputRef.current;
        const stopPropagationSubmit = (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            if (listSearch.length === 0)
                return;
            handleClick(listSearch[0].id);
        }
        (inputRef.current.parentElement as HTMLFormElement).addEventListener("submit", stopPropagationSubmit);
        return () => {
            (inputRefCleanup.parentElement as HTMLFormElement).removeEventListener("submit", stopPropagationSubmit);

        }
    }, [inputRef, listSearch, handleClick])


    return (
        <>
            <input ref={inputRef} type="search" placeholder="Rechercher un événement" className={props.className} value={search} onChange={(e) => handleSearch(e.target.value)} />
            {focus && search && <table className={styles.FullSearch}>
                <tbody>
                    {listSearch.map((elm) => (
                        <tr className={styles.elmSearched} key={elm.id} onClick={() => handleClick(elm.id)}>
                            <td>
                                <MiniDate date={elm.dateTimeStart} className={styles.MiniDate} />
                            </td>
                            <td className={styles.elmEvent}>
                                <div>
                                    {elm.name}
                                </div>
                                <div className={styles.assoName}>
                                    {elm.association}
                                </div>
                            </td>
                            <td className={styles.elmEvent}>
                                <Button>Voir</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </>
    )
}

const mapStateToProps = (state: any): ReduxPropsState => ({
    fetched: state.events.fetched,
    events: state.events.events,
})

export default (withRouter(connect(mapStateToProps)(Search) as any)) as any as React.FC<askProps>;