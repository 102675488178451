import { TicketModel } from "../../Models/Ticket";
import Redux from 'redux';

export enum TicketActions {
    FETCH_TICKETS = 'FETCH_TICKETS',
}

export const FetchTickets = (tickets: TicketModel[]): Redux.AnyAction => ({
    type: TicketActions.FETCH_TICKETS,
    payload: tickets,
});
