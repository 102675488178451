import React from 'react';
import Date from '../../Utils/Date';
import { DOMINANT_COLOR} from "../Consts";

interface props {
    dateTimeStart: string,
    dateTimeEnd: string,
    className?: string,
}

export default class MiniTime extends React.Component<props> {

    private StartHour: string = "00";
    private StartMinute: string = "00";
    private EndHour: string = "00";
    private EndMinute: string = "00";
    styleTop: React.CSSProperties;

    constructor(props: props) {
        super(props);

        let itos = (elm: number): string => {
            let toRet = String(elm);
            if (elm < 10)
                toRet = "0" + toRet;
            return toRet;

        }

        let dateStart = new Date(props.dateTimeStart);
        let dateEnd = new Date(props.dateTimeEnd);
        this.StartHour = itos(dateStart.Hour);
        this.StartMinute = itos(dateStart.Minute);
        this.EndHour = itos(dateEnd.Hour);
        this.EndMinute = itos(dateEnd.Minute);
        this.styleTop = {
            color: DOMINANT_COLOR,
        }
    }

    render() {
        return <div className={"MiniTime " + this.props.className}>
            <div className="topMiniTime" style={this.styleTop}>
                {this.StartHour}:{this.StartMinute}
            </div>
            <div className="botMiniTime">
                {this.EndHour}:{this.EndMinute}
            </div>
        </div>
    }
}