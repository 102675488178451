import { StructureModel } from '../../../../Models/Structure';
import Redux from 'redux';

import { StructureActions } from '../Actions/structure';

interface state {
    structures: StructureModel[],
    fetched: boolean,
}

const defaultState: state = {
    structures: [],
    fetched: false,
}

export function StructureReducer(state: state = defaultState, action: Redux.AnyAction): state {
    switch (action.type) {
        case StructureActions.FETCH_STRUCTURE_ADMIN: {
            return {
                ...state,
                structures: action.payload,
                fetched: true,
            }
        }
        default: {
            return state;
        }
    }
}