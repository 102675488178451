import { UserRightsModel } from "../../../../Models/UserRights";
import Redux from 'redux';

export enum UserRightsAction {
    FETCH = "FETCH_USER_RIGHTS",
    SET_RIGHTS = "SET_RIGHTS",
}

export const FetchUserRightsAction = (UserRightsPayload: UserRightsModel): Redux.AnyAction => ({
    type: UserRightsAction.FETCH,
    payload: UserRightsPayload,
})


export function SetRights(right: number): Redux.AnyAction {
    return {
        type: UserRightsAction.SET_RIGHTS,
        payload: right,
    }
}