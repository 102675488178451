import redux from 'redux';
import {HeaderActions, UpdateNamePayload} from '../Actions/HeaderActions';

interface state {
    id: number | undefined,
    name: string,
    email: string,
    lastname: string,
    firstname: string,
    connected: boolean,
    offline: boolean,
}

const initState: state = {
    id: undefined,
    name: "",
    email: "",
    lastname: "",
    firstname: "",
    connected: false,
    offline: false,
}

export function HeaderReducer(state : state = initState, action: redux.AnyAction): state {
    switch (action.type) {
        case HeaderActions.UPDATE_NAME: {
            let actionUpdate = action as UpdateNamePayload;
            let newState = {
                ...state,
                id: actionUpdate.id,
                name: actionUpdate.name,
                email: actionUpdate.email,
                firstname: actionUpdate.firstname,
                lastname: actionUpdate.lastname,
                connected: true,
            }
            return newState;
        }
        case HeaderActions.DISCONNECT_NAME: {
            let newState = {
                ...state,
                connected: false,
            }
            return newState;
        }
        case HeaderActions.OFFLINE_SET: {
            return {
                ...state,
                offline: action.payload,
            }
        }
        default:
            return state;
    }
}