import React from 'react';
import { EventModel } from '../../Models/Event';
import './FrontViewEventFocus.css'
import { Container } from 'react-bootstrap';
import MiniDate from '../MainPage/Minidate';
import MiniTime from '../MainPage/MiniTime';
import MiniPlace from '../MainPage/MiniPlace';

interface state {
    show: boolean,
    hover: boolean,
}

interface props {
    event: EventModel,
}

const crossRotateShow: React.CSSProperties = {
    transform: 'rotate(45deg)',
}

const crossRotateHidden: React.CSSProperties = {
    transform: 'rotate(0deg)',
}

const crossRotateOver: React.CSSProperties = {
    transform: 'rotate(180deg)',
}

const hiddenFocus: React.CSSProperties = {
    opacity: 0,
}

export default class FrontViewEventFocus extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            show: true,
            hover: false,
        }
    }

    handleHover = () => {
        this.setState({
            hover: true,
        })
    }

    handleNotHover = () => {
        this.setState({
            hover: false
        })
    }

    handleShow = () => {
        this.setState({
            show: !this.state.show,
        })
    }

    render() {

        let defaultStyleBG: React.CSSProperties = {
            backgroundImage: "url('" + this.props.event.imgUrl + "')",
        }

        let styleCross: React.CSSProperties;
        if (this.state.hover) {
            styleCross = crossRotateOver;
        }
        else if (this.state.show) {
            styleCross = crossRotateShow;
        }
        else {
            styleCross = crossRotateHidden;
        }

        let defaultStyleShow: React.CSSProperties = {};
        if (!this.state.show) {
            defaultStyleShow = hiddenFocus;
        }

        return <div id="FrontViewEventFocus">
            <div className="FrontBG" style={defaultStyleBG}>
                <Container>
                    <div className="WheelCross" style={styleCross} onClick={this.handleShow} onMouseOver={this.handleHover} onMouseOut={this.handleNotHover}/>
                    <div />
                    <div className="EventContainer col-md-10" style={defaultStyleShow}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="WheelIdAsso"/>
                                    </td>
                                    <td>
                                        <div className="EventName">
                                            {this.props.event.name}
                                        </div>
                                        <div className="EventAsso">
                                            {this.props.event.association}
                                        </div>
                                        <MiniDate date={this.props.event.dateTimeStart} className="MiniDateFocus ItemFocus"/>
                                        <MiniTime dateTimeStart={this.props.event.dateTimeStart} dateTimeEnd={this.props.event.dateTimeEnd} className="MiniTimeFocus ItemFocus"/>
                                        <MiniPlace className="MiniPlaceFocus ItemFocus">Le Kremlin-Bicêtre</MiniPlace>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>
        </div>
    }
}