import React from 'react';
import { Link } from 'react-router-dom';

export default function InvalidEvent() {
    return (<div id="EventError">
        <h2>L'événement n'existe pas</h2>
        <Link to="/">
            <div className="LinkError">
                Revenir à l'accueil
            </div>
        </Link>
    </div>)
}