import React from 'react';
import './App.css';
import Header from './Components/Layers/Header'
import Store from './Redux/Store';
import { Provider } from 'react-redux'
import MainPage from './Components/MainPage/MainPage';
import { HashRouter, Route } from 'react-router-dom';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import EventList from './Components/EventList/EventList'
import EventFocus from './Components/EventFocus/EventFocus';
import InitConnectHandler from './Utils/InitConnected';
import Profile from './Components/Profile/Profile';
import Ticket from './Components/Ticket/Ticket';
import 'antd/dist/antd.css';
import { Icon } from 'antd';
import Offline from './Components/Offline/Offline';

const AdminLoading = React.lazy(() => import('./Components/Admin/Admin'));

InitConnectHandler();

const Admin = () => {
  return <>
    <React.Suspense fallback={<Icon type="loading" style={{fontSize: 30}}/>}>
      <AdminLoading />
    </React.Suspense>
  </>
}


const App: React.FC = () => {
  return (
    <div className="App">
      <Provider store={Store}>
        <HashRouter>
          <Header />
          <Route path="/" exact component={MainPage} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register}/>
          <Route path="/eventlist" component={EventList}/>
          <Route path="/eventfocus/:id" component={EventFocus} />
          <Route path="/admin" component={Admin} />
          <Route path="/profile" component={Profile} />
          <Route path="/ticket/:id" component={Ticket} />
        </HashRouter>
        <Offline />
      </Provider>
    </div>
  );
}

export default App;
