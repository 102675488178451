import React from 'react';
import Carousel from './Carroussel';
import EventList from './EventList';
import Footer from '../Layers/Footer'
import Search from '../Layers/Search';
import { Container } from 'react-bootstrap';
import styles from "./MainPage.module.css";

interface props {

}
export default class MainPage extends React.Component<props> {

    constructor(props: props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="MainPage">
            <Carousel />

            {(window.outerWidth < 700) &&

                <Container className={styles.searchContain}>
                    <form>
                        <Search className={styles.search} />
                    </form>
                </Container>
            }
            <EventList />
            <Footer />
        </div>
    }
}