import React from 'react';
import { Container } from 'react-bootstrap';
import Line from '../Layers/Line';
import Field from './Field';
import { DOMINANT_COLOR } from '../Consts';
import { connect } from 'react-redux';
import Redux from 'redux';
import { UpdateNameAction, ArgsUpdateName } from '../../Redux/Actions/HeaderActions';
import LoginUtil from '../../Utils/Login';
import Loader from '../Layers/Loader';
import { Link, Redirect } from 'react-router-dom';
import { api_url } from '../../Settings';

const styleSize: React.CSSProperties = {
    height: (window.innerHeight / 2) - 20,
}

const styleBtn: React.CSSProperties = {
    backgroundColor: DOMINANT_COLOR,
}

interface props {
    updateName: (elm: ArgsUpdateName) => void,
    connected: boolean,
}

interface state {
    username: string,
    password: string,
    redirect: boolean,
    error: boolean,
    kinderr: string
    loading: boolean,
}

class Login extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            redirect: props.connected,
            loading: false,
            error: false,
            kinderr: "",
        }

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUpdate(target: "username" | "password" , payload: string) {
        (this.setState as any)({
            [target]: payload,
            error: false,
        })
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.setState({
            loading: true,
        })
        LoginUtil.Login(this.state.username, this.state.password)
            .then((toUpdate: ArgsUpdateName) => {
                this.props.updateName(toUpdate);
                this.setState({
                    loading: false,
                    redirect: true,
                })
            })
            .catch((kind: Error) => {
                this.setState({
                    loading: false,
                    error: true,
                    kinderr: kind.message,
                })
            })

    }

    render() {
        return <div className="Login">
            {this.state.redirect ?
                <Redirect to="/" /> :
                <div />
            }
            <div className="LoginBg" style={styleSize} />
            <div className="LoginCenter">
                <Container>
                    <div className="col-md-5">
                        <div className="CardLogin">
                            <form>
                                <div className="TitleCard">Connexion</div>
                                <Line />
                                <Field typeField="text" className="FieldElm" onChange={this.handleUpdate} target="username">Nom d'utilisateur</Field>
                                <Field typeField="password" className="FieldElm" onChange={this.handleUpdate} target="password">Mot de passe</Field>

                                {(this.state.error) ?
                                    <div className="ErrorLogin">{this.state.kinderr}</div> :
                                    <div />
                                }

                                {(this.state.loading) ?
                                    <Loader /> :
                                    <button type="submit" className="ButtonField" style={styleBtn} onClick={this.handleSubmit}>Valider</button>
                                }
                            </form>
                        </div>
                        <div className="SubButton">
                            <a href={api_url + "/cri_connect"}>
                            <div className="SubButtonElm">
                                CRI CONNECT
                            </div>
                            </a>
                            <Link to="/register">
                                <div className="SubButtonElm">
                                    S'inscrire
                                </div>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    }
}

function mapStateToProps(state: any) {
    return {
        connected: state.header.connected,
    }
}

function mapDispatchToProps(dispatcher: Redux.Dispatch) {
    return {
        updateName: (elm: ArgsUpdateName) => dispatcher(UpdateNameAction(elm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);