import React from 'react'

interface props {
    children: any,
    className?: string,
}

export default class MiniPlace extends React.Component<props> {
    render() {
        return <div className={"MiniPlace " + this.props.className}>
        {this.props.children}
        </div>
    }
}