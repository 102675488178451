import {combineReducers, createStore} from 'redux';
import { HeaderReducer } from './Reducers/HeaderReducer';
import { EventReducer } from './Reducers/EventReducer';
import { StructureReducer } from '../Components/Admin/ReduxAdmin/Reducers/structures';
import { UtilsReducer } from '../Components/Admin/ReduxAdmin/Reducers/utils';
import { UserRightsReducer } from '../Components/Admin/ReduxAdmin/Reducers/user_rights';
import { EventAdminReducer } from '../Components/Admin/ReduxAdmin/Reducers/event';
import { TicketReducer } from './Reducers/TicketReducer';


const reducers = combineReducers({
    header: HeaderReducer,
    events: EventReducer,
    tickets: TicketReducer,
    admin: combineReducers({
        structures: StructureReducer,
        utils: UtilsReducer,
        user_rights: UserRightsReducer,
        events: EventAdminReducer,
    })
});

export default createStore(
    reducers,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);