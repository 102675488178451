import { EventActions } from '../Actions/event';
import { EventAdminModel } from '../../../../Models/EventAdmin';
import Redux from 'redux';

interface state {
    events: EventAdminModel[],
    fetched: boolean,
}

const defaultState: state = {
    events: [],
    fetched: false,
}

export const EventAdminReducer = (state: state = defaultState, action: Redux.AnyAction): state => {
    switch (action.type) {
        case EventActions.FETCH: {
            return {
                ...state,
                events: action.payload,
                fetched: true,
            }
        }
        default: return state;
    }
}