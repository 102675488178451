import axios from 'axios';
import { api_url } from '../Settings';
import TicketHelper from './TicketHelper';
import cr from './ConnectedRequest';

export default class PayementHelper {

    /**
     * Renseign a payment to the server
     */
    public static async MakePayement(data: any, event_id: number, user_id: number): Promise<void> {
        try {
            var dataRes = await axios.get(api_url + "/payment/" + data.paymentToken + "?user_id=" + user_id + "&event_id=" + event_id);
        } catch (e) {
            console.log(e.response);
            throw Error("Impossible de valider la transaction");
        }
        TicketHelper.FetchTickets();
        console.log(dataRes);
    }

    /**
     * Get a free ticket from the API
     * @param event_id The event id
     * @param external If the buyer bought it as an external of IONIS
     */
    public static async MakeTicketFree(event_id: number, external: boolean): Promise<void> {
        let data = await cr.post(api_url + "/ticket_free/jwt/create/?event_id=" + event_id + "&external=" + String(external));
        if (!data)
            throw Error("Impossible de créer le ticket");
    }
}