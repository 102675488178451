import { TicketActions } from '../Actions/TicketActions';
import { TicketModel } from '../../Models/Ticket';
import Redux from 'redux';

interface state {
    tickets: TicketModel[],
    fetched: boolean,
}

const defaultState: state = {
    tickets: [],
    fetched: false,
}

export const TicketReducer = (state: state = defaultState, action: Redux.AnyAction): state => {
    switch (action.type) {
        case TicketActions.FETCH_TICKETS: {
            return {
                tickets: action.payload,
                fetched: true,
            }
        }
        default:
            return state;
    }
}