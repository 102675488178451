import { UserRightsModel } from "../../../../Models/UserRights";
import { UserRightsAction } from '../Actions/user_rights';
import Redux from 'redux';

interface state {
    user_rights: UserRightsModel,
    fetched: boolean,
    rights: number,
}

const defaultState: state = {
    user_rights: [],
    fetched: false,
    rights: 1,
}

export const UserRightsReducer = (state: state = defaultState, action: Redux.AnyAction): state => {
    switch(action.type) {
        case UserRightsAction.FETCH: {
            return {
                ...state,
                user_rights: action.payload,
                fetched: true,
            }
        }
        case UserRightsAction.SET_RIGHTS: {
            return {
                ...state,
                rights: action.payload,
            }
        }
        default:
            return state;
    }
}