import { ArgsUpdateName } from '../Redux/Actions/HeaderActions';
import axios, { AxiosError } from 'axios';
import ConnectedRequest from './ConnectedRequest';
import { api_url as api } from '../Settings';
import Store from '../Redux/Store';
import { DisconnectNameAction as DisconectAction } from '../Redux/Actions/HeaderActions';

class Login {

    /**
     * Init login
     * @param username The user username
     * @param passwd The user password
     */
    public async Login(username: string, passwd: string): Promise<ArgsUpdateName> {

        // Check valid Username
        if (username.length < 3)
            throw Error("Mauvaise addresse Mail")

        // Check valid passwd
        if (passwd.length < 6)
            throw Error("Mauvais mot de passe");

        let data;
        try {
            data = await axios.post(api + "/login/", {
                username: username,
                password: passwd,
            });
        } catch (err) {
            let errAx = err as AxiosError;
            if (!(errAx['response'] as any['data']['detail']))
                throw Error(err);
            throw Error((errAx as any).response.data.detail);
        }
        ConnectedRequest.setAccess(data.data.access);
        ConnectedRequest.setRefresh(data.data.refresh);
        ConnectedRequest.isConnected = true;

        let dataBis = await ConnectedRequest.get("/get_user_info/");
        if (!dataBis)
            throw Error("Can't get user informations");

        localStorage.setItem("save-header", JSON.stringify(dataBis.data));
        return dataBis.data;
    }

    /**
     * Disconect User handler
     */
    public async Diconnect(): Promise<void> {
        try {
            ConnectedRequest.handleDisconnected();
        } catch (e) {};
        localStorage.removeItem("save-header");
        Store.dispatch(DisconectAction());
    }

}

export default new Login();