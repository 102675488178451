import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TicketModel } from '../../Models/Ticket';
import { Skeleton, Empty } from 'antd';
import TicketHelper from '../../Utils/TicketHelper';
import Ticket from './Ticket';

interface reduxProps {
    tickets: TicketModel[],
    fetched: boolean,
    connected: boolean,
}

interface props extends reduxProps {

}


const TicketList = (props: props) => {

    useEffect(() => {
        if (!props.fetched && props.connected) {
            TicketHelper.FetchTickets();
        }
    }, [props.connected, props.fetched])

    if (!props.fetched && props.connected) {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: "15px 15px",
                    border: "1px solid rgba(0, 0, 0, 0.1)"
                }}>

                <Skeleton active />

            </div>
        )
    }

    if (!props.connected || props.tickets.length === 0) {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: "15px 15px",
                    border: "1px solid rgba(0, 0, 0, 0.1)"
                }}>
                    <Empty description="Aucun tickets"/>
            </div>
        )
    }

    return (
        <div className="TicketList">
            {props.tickets.map((elm: TicketModel) =>
                <div key={elm.id} className="TicketElmList">
                    <Ticket ticket={elm}/>
                </div>
                )}
        </div>
    )

}

const mapStateToProps = (state: any): reduxProps => ({
    fetched: state.tickets.fetched,
    tickets: state.tickets.tickets,
    connected: state.header.connected,
})

export default connect(mapStateToProps)(TicketList);