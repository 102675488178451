import React from 'react';
import { EventModel } from '../../Models/Event';
import Line from '../Layers/Line';
import DateEvent from '../../Utils/Date';
import { DOMINANT_COLOR, DAYS } from '../Consts';

interface props {
    event: EventModel,
}

const styleDate: React.CSSProperties = {
    color: DOMINANT_COLOR,
}


export default class EventFocusPick extends React.Component<props> {

    private styleImg: React.CSSProperties;
    private dateStart: string = "";
    private startTime: string = "";
    private dateEnd: string = "";
    private endTime: string = "";
    private dayStart: string = "";
    private dayEnd: string = "";

    constructor(props: props) {
        super(props);
        this.styleImg = {
            backgroundImage: "url('" + props.event.imgUrl + "')",
        }
        let dateEventStart = new DateEvent(props.event.dateTimeStart)
        let dateEventEnd = new DateEvent(props.event.dateTimeEnd);

        if (dateEventStart.Valid && dateEventEnd.Valid && dateEventStart.date && dateEventEnd.date) {
            this.dateStart = dateEventStart.DateToStringWithMonth(4);
            this.startTime = dateEventStart.TimeToString();

            this.dateEnd = dateEventEnd.DateToStringWithMonth(4);
            this.endTime = dateEventEnd.TimeToString();

            this.dayStart = DAYS[dateEventStart.date.getDay()].substr(0, 3);
            this.dayEnd = DAYS[dateEventEnd.date.getDay()].substr(0, 3);
        }
    }

    render() {
        return <div className="EventFocusPick">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div style={this.styleImg} className="ImgFocusEvent"></div>
                        </td>
                        <td>
                            <div className="DataFocus">
                                <div className="TitleDataFocus">
                                    {this.props.event.name}
                                    <Line />
                                </div>
                                <div className="AssosDataFocus">
                                    {this.props.event.association}
                                </div>
                                <div className="DayEventWeek">
                                    {this.dayStart}
                                </div>
                                <div className="DateEvent" style={styleDate}>
                                    {this.dateStart}
                                </div>
                                <div className="TimeEvent">
                                    {this.startTime}
                                </div>
                                <div className="DayEventWeek">
                                    {this.dayEnd}
                                </div>
                                <div className="DateEvent" style={styleDate}>
                                    {this.dateEnd}
                                </div>
                                <div className="TimeEvent">
                                    {this.endTime}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}