import React from 'react';
import Date from '../../Utils/Date';
import { DOMINANT_COLOR, MONTHS } from "../Consts";

interface props {
    date: string,
    className?: string,
}

export default class MiniDate extends React.Component<props> {

    private Month: string = "";
    private Day: number = 0;
    styleTop: React.CSSProperties;

    constructor(props: props) {
        super(props);
        let date = new Date(props.date);
        let miniMonth = MONTHS[date.Month - 1];
        if (!miniMonth) {
            this.Month = ""
        }
        else {
        this.Month = miniMonth.substr(0, 4);
        }
        this.Day = date.Day;
        this.styleTop = {
            color: DOMINANT_COLOR
        }
    }

    render() {
        return <div className={"MiniDate " + this.props.className}>
            <div className="topMiniDate" style={this.styleTop}>
                {this.Month}
            </div>
            <div className="botMiniDate">
                {this.Day}
            </div>
        </div>
    }
}