const getFieldFromItem = (elm: any, pos: string): string | undefined => {
    let posi = pos.split(".");
    if (posi.length === 0)
        return undefined;
    if (posi.length === 1) {
        return String(elm[posi[0]]);
    }
    return getFieldFromItem(elm[posi[0]], posi.join("."));
}

export default class SearchHelper {
    /**
     * Search elements on a Array
     * @param elms The raw array
     * @param search The search payload (generaly user input)
     * @param searchOn Fields of T you want to search
     */
    static Search<T>(elms: T[], search: string | undefined, searchOn: string[]): T[] {
        if (!search || search.trim() === "")
            return elms;

        const query = (item: T): boolean => {
                for (let itemSearch of searchOn) {
                    let value = getFieldFromItem(item, itemSearch);
                    if (!value)
                        continue;
                    if (value.toLowerCase().includes(search.toLowerCase()))
                        return true;
                }
            return false;
        }
        return elms.filter(query);
    }
}