import React from 'react';
import redux from 'redux';
import { connect } from 'react-redux';
import { FetchEvent, FetchEventArgAction } from '../../Redux/Actions/EventActions';
import { EventModel } from '../../Models/Event';
import { Carousel } from 'react-bootstrap'
import FetchEventAxios from '../../Utils/FetchEvents';
import View from './View';
import Loader from '../Layers/Loader';

interface props {
    events: EventModel[],
    fetch: Function,
    fetched: boolean,
}

interface state {
    events: EventModel[],
}

class CarrusselView extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleFetch();
        this.state = {
            events: props.events.slice(0, 3),
        }
    }

    async handleFetch() {
        if (!this.props.fetched)
            return;
        FetchEventAxios.fetch();
    }

    componentWillReceiveProps(nextProps: props) {
        this.setState({
            events: nextProps.events.slice(0, 3),
        })
    }

    render() {
        return <div className="Carousel">
            <Carousel id="EventCarousel" interval={0}>
                {(this.state.events && this.state.events.length) ?
                    this.state.events.map((elm: EventModel, key: number) =>
                        <Carousel.Item key={elm.id} className="CarouselItem">
                            <div className="d-block w-100 CarouselBlock">
                                <View event={elm} />
                            </div>
                        </Carousel.Item>
                    ) :
                    <div className="CarouselItem NoShow">
                        <Loader timer={10000} errorMsg="Impossible d'afficher les événements">Chargement</Loader>
                    </div>
                }
            </Carousel>
        </div>
    }
}

function mapStateToProps(state: any) {
    return {
        events: state.events.events,
        fetched: state.events.fetched,
    };
}

function mapDispatcheToProps(dispatcher: redux.Dispatch) {
    return {
        fetch: (args: FetchEventArgAction) => dispatcher(FetchEvent(args)),
    }
}

export default connect(mapStateToProps, mapDispatcheToProps)(CarrusselView);