import React from 'react';
import { EventModel } from '../../Models/Event';
import { Container } from 'react-bootstrap';
import Line from '../Layers/Line';
import MiniDate from './Minidate';
import MiniTime from './MiniTime';
import MiniPlace from './MiniPlace';
import { Link } from 'react-router-dom';

interface props {
    event: EventModel,
}

export default class View extends React.Component<props> {

    private bgStyles: React.CSSProperties;

    constructor(props: props) {
        super(props);
        this.bgStyles = {
            backgroundImage: "url('" + props.event.imgUrl + "')",
        }
    }

    render() {
        return <div className="CarouselBgView" style={this.bgStyles}>
            <Container>
                <div className="col-md-6 FirstCardPres">
                    <div className="CardTitle">
                        {this.props.event.name}
                        <Line width={50} heigth={6}/>
                    </div>
                    <div className="CardAsso">
                        {this.props.event.association}
                    </div>
                </div>
                <div className="subCard col-md-6">
                    <MiniDate date={this.props.event.dateTimeStart} className="MiniDateElm"/>
                    <MiniTime dateTimeStart={this.props.event.dateTimeStart} dateTimeEnd={this.props.event.dateTimeEnd} className="MiniTimeElm"/>
                    <MiniPlace className="MiniPlaceElm">Le Kremlin-Bicêtre</MiniPlace>
                </div>
                <Link to={"/eventfocus/" + this.props.event.id}>
                    <div className="buttonSee">
                        Voir
                    <Line heigth={2} width={15} />
                    </div>
                </Link>
            </Container>
        </div>
    }
}
