import { EventSaveModel } from '../EventSaveModel';
import { AnyAction } from 'redux';

export enum Actions {
    SAVE_EVENT = 'SAVE_EVENT_ADMIN'
}
export function SaveEvent(event: EventSaveModel | undefined): AnyAction {
    return {
        type: Actions.SAVE_EVENT,
        payload: event,
    }
}