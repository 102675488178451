import React from 'react';
import Line from '../Layers/Line';
import Loader from '../Layers/Loader';
import Register from '../../Utils/Register';
import { ArgsUpdateName, UpdateNameAction } from '../../Redux/Actions/HeaderActions';
import { connect } from 'react-redux';
import Redux from 'redux';
import { Redirect } from 'react-router-dom';

interface props {
    updateName: (args: ArgsUpdateName) => void,
    connected: boolean,
}

interface state {
    nom: string,
    prenom: string,
    username: string,
    mail: string,
    birth: string,
    password: string,
    repassword: string,
    loading: boolean,
    error: string | null,
    redirect: boolean,
}

class Pannel extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            nom: "",
            prenom: "",
            username: "",
            mail: "",
            birth: "",
            password: "",
            repassword: "",
            loading: false,
            error: null,
            redirect: props.connected,
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUpdate(event: any) {
        (this.setState as any)({
            [event.target.name]: event.target.value,
            error: null,
        });
    }

    handleSubmit() {
        this.setState({
            loading: true,
        })
        let payload = {...this.state};
        delete payload.loading;
        delete payload.error;
        delete payload.redirect;
        Register.register(payload)
            .then(() => {
                this.setState({
                    redirect: true,
                });
            })
            .catch((err: Error) => {
                this.setState({
                    error: err.message,
                    loading: false,
                })
            })
    }

    render() {
        return <div className="PannelRegister col-lg-6">
            {this.state.redirect ? <Redirect to="/login" /> : <div />}
            <div className="TitlePannel">S'inscrire</div>
            <Line />
            <div className="container_fluid AllInput">
                <div className="col-md-6 divInputRegister">
                    <input type="text" name="nom" placeholder="Nom" value={this.state.nom} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div className="col-md-6 divInputRegister">
                    <input type="text" name="prenom" placeholder="Prénom" value={this.state.prenom} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div className="col-md-12 divInputRegister">
                    <input type="text" name="username" placeholder="Nom d'utilisateur" value={this.state.username} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div className="col-md-12 divInputRegister">
                    <input type="email" name="mail" placeholder="Addresse Mail" value={this.state.mail} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div className="col-md-6 divInputRegister">
                    <input type="date" name="birth" placeholder="Date de naissance" value={this.state.birth} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div />
                <div className="col-md-6 divInputRegister">
                    <input type="password" name="password" placeholder="Mot de passe" value={this.state.password} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                <div className="col-md-6 divInputRegister">
                    <input type="password" name="repassword" placeholder="Répéter le mot de passe" value={this.state.repassword} onChange={this.handleUpdate} className="inputRegister" />
                </div>
                {this.state.error ?
                    <div className="ErrorRegister">{this.state.error}</div> :
                    <div />
                }
                {this.state.loading ?
                    <Loader /> :
                    <button type="submit" className="RegisterValidate" onClick={this.handleSubmit}>Valider</button>
                }
            </div>
        </div>
    }
}

function mapDispatchToProps(dispaptch: Redux.Dispatch) {
    return {
        updateName: (elm: ArgsUpdateName) => dispaptch(UpdateNameAction(elm)),
    }
}

function mapStateToProps(state: any) {
    return {
        connected: state.header.connected,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pannel);