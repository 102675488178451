import React, {useEffect} from 'react';
import "./Profile.css";
import { connect } from 'react-redux';
import { UserModel } from '../../Models/User';
import { Container } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import TicketList from './TicketList';
import Footer from '../Layers/Footer';
import { Tag } from 'antd';

interface propsRedux {
    user: UserModel,
    connected: boolean,
}

interface props extends propsRedux {
}

const Profile = (props: props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!props.connected && !localStorage.getItem("access_token")) {
        return <Redirect to="/login" />
    }

    let firstLetter = "";
    if (props.user.username.length >= 1) {
        firstLetter = props.user.username[0];
    }

    return (
        <div id="Profile">
            <div id="BlueBack"/>
            <Container>
                <h2 className="TitleNameProfile">{props.user.firstname} {props.user.lastname}</h2>
            </Container>
            <Container>
                <div className="TicketListProfile col-lg-9">
                    <h2 className="TicketListProfileTitle">Tickets</h2>
                    <TicketList />
                </div>
                <div className="InfoProfile col-lg-9">
                    <h2 className="InfoProfileTitle">Informations</h2>
                    <div className="InfoUserElm">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="AvatarElmProfile">{firstLetter}</div>
                                    </td>
                                    <td>
                                        <div>{props.user.firstname} {props.user.lastname}</div>
                                        <div className="UsernameProfileInfo">{props.user.username}</div>
                                        <Tag color="blue">{props.user.email}</Tag>
                                    </td>
                                    <td>
                                        <Link to="#">
                                            <div className="ButtonUpdateProfileInfo">Modifier</div>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state: any): propsRedux => ({
    user: {
        id: state.header.id,
        email: state.header.email,
        username: state.header.name,
        firstname: state.header.firstname,
        lastname: state.header.lastname,
    },
    connected: state.header.connected,
})

export default connect(mapStateToProps)(Profile);