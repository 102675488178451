import React from 'react';
import { EventModel } from '../../Models/Event';
import Line from '../Layers/Line';
import LeftArrow from './left_arrow.png';
import RightArrow from './right_arrow.png';
import EventFocusPick from './EventFocusPick';
import { Link } from 'react-router-dom';

const StyleLeftArrow: React.CSSProperties = {
    backgroundImage: "url('" + LeftArrow + "')",
    left: 10,
}

const widthElmFocus = 360;

const StyleSizeElmFocus: React.CSSProperties = {
    width: widthElmFocus,
}

const StyleRightArrow: React.CSSProperties = {
    backgroundImage: "url('" + RightArrow + "')",
    right: 10,
}

interface props {
    events: EventModel[],
    children: string,
}

interface state {
    posiX: number,
    img_pos: number,
    widthCarusel: number,
}
export default class EventPannel extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            posiX: 0,
            img_pos: 0,
            widthCarusel: widthElmFocus * props.events.length,
        }
        this.handleLeft = this.handleLeft.bind(this);
        this.handleRight = this.handleRight.bind(this);
    }

    componentWillReceiveProps(newProps: props) {
        if (newProps === this.props)
            return;
        this.setState({
            widthCarusel: widthElmFocus * newProps.events.length,
        });
    }

    handleLeft() {
        if (this.state.img_pos <= 0 || this.state.posiX <= 0) {
            this.setState({
                posiX: 0,
                img_pos: 0,
            });
            return;
        }
        let domElements = document.getElementsByClassName("ElmEventFocus");
        if (domElements.length === 0)
            return;
        if (!domElements[0])
            return;
        this.setState({
            posiX: this.state.posiX - widthElmFocus,
            img_pos: this.state.img_pos - 1,
        });
    }

    handleRight() {
        if (this.state.img_pos >= this.props.events.length - 1) {
            return;
        }
        let domElements = document.getElementsByClassName("ElmEventFocus");
        if (domElements.length === 0)
            return;
        if (!domElements[0])
            return;
        this.setState({
            posiX: this.state.posiX + widthElmFocus,
            img_pos: this.state.img_pos + 1,
        });
    }

    render() {
        let styleModify: React.CSSProperties = {
            transform: "translateX(-" + this.state.posiX + "px)",
            width: this.state.widthCarusel,
        }

        return <div className="EventPannelList">
            <div className="TitleEvent">
                {this.props.children}
                <Line />
            </div>
            <div className="PannelListEventsElms">
                <div className="ArrowEventList" style={StyleLeftArrow} onClick={this.handleLeft}/>
                <div className="ArrowEventList" style={StyleRightArrow} onClick={this.handleRight}/>
                <div className="AllElmListEvents">
                    <div className="CarouselEventList" style={styleModify}>
                    {this.props.events.map((elm: EventModel, key: number) =>
                        <div key={key} className="ElmEventFocus" style={StyleSizeElmFocus}>
                            <Link to={"/eventfocus/" + elm.id}>
                                <EventFocusPick event={elm} />
                            </Link>
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </div>
    }
}