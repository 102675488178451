import { connect } from 'react-redux';
import { EventModel } from '../../Models/Event'
import React from 'react';
import redux from 'redux';
import EventFetch from '../../Utils/FetchEvents';
import { FetchEventArgAction, FetchEvent } from '../../Redux/Actions/EventActions';
import { Container } from 'react-bootstrap';
import Line from '../Layers/Line';
import Loader from '../Layers/Loader'
import EventCase from '../Layers/EventCase/Eventcase'
import { Link } from 'react-router-dom';

type fetchFn = (elm: FetchEventArgAction) => {};

interface props {
    events: EventModel[],
    fetch: fetchFn,
    fetched: boolean,
}

interface state {
    events: EventModel[],
}

class EventList extends React.Component<props, state> {

    constructor(props: props) {
        super(props);

        if (!props.fetched) {
            this.HandleFetchEvent();
        }

        this.state = {
            events: props.events.slice(0, 7),
        }

        this.HandleFetchEvent.bind(this);

    }

    componentWillReceiveProps(newProps: props) {
        this.setState({
            events: newProps.events.slice(0, 7),
        })
    }

    private async HandleFetchEvent(): Promise<void> {
        EventFetch.fetch();
    }

    render() {
        return <Container className="EventList">
            <h3>Prochains événements</h3>
            <Line />
            <div className="EventListContainer">
                {(this.state.events.length === 0) ?
                    <Loader timer={10000} errorMsg="Impossible de charger les événements" className="NoShow">Chargement des événements</Loader>
                    :
                    <div>
                        {this.state.events.map((elm: EventModel, key: number) =>
                            <Link to={"/eventfocus/" + elm.id} key={elm.id} >
                                <EventCase event={elm}/>
                            </Link>
                        )}
                        <Link to="/eventlist">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 EventCase">
                                <div className="EventListSeeAll">
                                    <div className="CenterSeeAll">
                                        Tout voir
                                <Line />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                }
            </div>
        </Container>
    }
}

function mapStateToProps(state: any) {
    return {
        events: state.events.events,
        fetched: state.events.fetched,
    };
}

function mapDispatchToProps(dispatcher: redux.Dispatch) {
    return {
        fetch: (elm: FetchEventArgAction) => dispatcher(FetchEvent(elm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);