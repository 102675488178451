import { StructureModel } from "../../../../Models/Structure";
import { AnyAction } from "redux";

export enum StructureActions {
    FETCH_STRUCTURE_ADMIN = 'FETCH_STRUCTURE_ADMIN',
}

export function FetchStructure(structures: StructureModel[]): AnyAction {
    return {
        type: StructureActions.FETCH_STRUCTURE_ADMIN,
        payload: structures,
    }
}