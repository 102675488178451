import React from 'react';
import { EventModel } from '../../../Models/Event'
import ImgEventCase from './ImgEventCase';
import MiniDate from '../../MainPage/Minidate'

interface props {
    event: EventModel
    className?: string,
}

export default class EventCase extends React.PureComponent<props> {

    render() {
        return <div className={"EventCase col-lg-3 col-md-4 col-sm-6 col-12 " + this.props.className}>
            <ImgEventCase url={this.props.event.imgUrl} />
            <table>
                <tbody>
                    <tr>
                        <td>
                            <MiniDate date={this.props.event.dateTimeStart} className="EventCaseDate" />
                        </td>
                        <td>
                            <div className="description">
                                <div>{this.props.event.name}</div>
                                <div>{this.props.event.association}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}