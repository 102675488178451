import React from 'react';
import './EventList.css';
import { Container } from 'react-bootstrap';
import { EventModel } from '../../Models/Event';
import Redux from 'redux';
import { connect } from 'react-redux';
import { FetchEvent, FetchEventArgAction } from '../../Redux/Actions/EventActions';
import EventManager from '../../Utils/FetchEvents';
import { GetNextEvents, GetTomorowEvents } from '../../Utils/EventHelper';
import EventPannel from './EventPannel';
import Loader from '../Layers/Loader';
import Footer from '../Layers/Footer';
import Line from '../Layers/Line';
import EventCase from '../Layers/EventCase/Eventcase';
import { Link } from 'react-router-dom';

interface props {
    events: EventModel[],
    fetchEvents: (elms: FetchEventArgAction) => void,
    fetched: boolean,
}

interface state {
    tomorowEvent: EventModel[],
    nextEvent: EventModel[],
    allEvent: EventModel[],
    loading: boolean,
}

class EventList extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.handleFetch = this.handleFetch.bind(this);

        this.state = {
            tomorowEvent: GetTomorowEvents(props.events),
            nextEvent: GetNextEvents(props.events),
            allEvent: props.events,
            loading: !props.fetched,
        };
        this.handleFetch();
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(newProps: props) {
        if (newProps === this.props)
            return;
        this.setState({
            tomorowEvent: GetTomorowEvents(newProps.events),
            nextEvent: GetNextEvents(newProps.events),
            allEvent: newProps.events,
            loading: newProps.events.length === 0,
        })
    }

    async handleFetch() {
        if (this.props.fetched)
            return;
        EventManager.fetch();
    }

    render() {
        return <div className="ListEventPage">
            <div className="BgFondListEvent" />
            <Container className="EventsContainer">
                <div className="TitleEvents">
                    Liste des événements
                </div>
                { this.state.loading ?
                    <div className="PannelContainerEventList LoaderPannel">
                        <Loader timer={10000} errorMsg="Impossible de charger les données">Chargement...</Loader>
                    </div> :
                    <div />
                }
                { this.state.tomorowEvent.length !== 0 ?
                    <div className="PannelContainerEventList">
                        <EventPannel events={this.state.tomorowEvent}>Aujourd'hui et demain</EventPannel>
                    </div> :
                    <div />
                }
                { this.state.nextEvent.length !== 0 ?
                    <div className="PannelContainerEventList">
                        <EventPannel events={this.state.nextEvent}>Prochainement</EventPannel>
                    </div> :
                    <div />
                }

                <div className="EventListFull">
                    <div className="TitleEventList">
                        Tout les événements
                        <Line />
                    </div>
                    <div className="AllFullEvents">
                        {this.state.loading ?
                            <Loader timer={10000} errorMsg="Impossible de charger les données">Chargement des événements</Loader> :
                            <div />
                        }
                        {this.state.allEvent.map((elm: EventModel) =>
                            <Link to={"/eventfocus/" + elm.id} key={elm.id} >
                                <EventCase event={elm}/>
                            </Link>
                        )}
                    </div>
                </div>

            </Container>
            <div style={{height: 50}} />
            <Footer />
        </div>
    }
}

function mapStateToProps(state: any) {
    return {
        events: state.events.events,
        fetched: state.events.fetched,
    }
}

function mapDispatchToProps(dispatcher: Redux.Dispatch) {
    return {
        fetchEvents: (elms: FetchEventArgAction) => dispatcher(FetchEvent(elms)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);