import React from 'react';
import Line from '../Line';

interface props {
    url: string,
}

export default class ImgEventCase extends React.Component<props> {

    private style: React.CSSProperties;

    constructor(props: props) {
        super(props);
        this.style = {
            backgroundImage: "url('" + props.url + "')",
        };
    }
    render() {
        return <div className="ImgEventCase" style={this.style}>
            <div className="VoirImgEventCase">
                <div className="CenterEventVoir">
                    Voir
                    <Line heigth={3} width={15} />
                </div>
            </div>
        </div>
    }
}