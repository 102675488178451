import React, { useState, useEffect } from 'react';
import './CardPayement.css'
import Arrow from './BlackArrowRight.png';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PayPal from 'react-paypal-express-checkout';
import PayementHelper from '../../Utils/PayementHelper';
import { EventModel } from '../../Models/Event';
import { Modal, Button, message, Icon } from 'antd';
import { TicketModel } from '../../Models/Ticket';
import TicketHelpers from '../../Utils/TicketHelper';

interface reduxProps {
    connected: boolean,
    user_id: number,
}
interface props extends reduxProps {
    children: string,
    startColor: string,
    endColor: string,
    pack: string[],
    price: number,
    event: EventModel,
    external: boolean,
}

class CardPayement extends React.Component<props> {

    private bgStyle: React.CSSProperties;

    constructor(props: props) {
        super(props);
        this.bgStyle = {
            background: "linear-gradient(135deg, " + props.startColor + " 0%, " + props.endColor + " 100%)",
        }
    }

    render() {
        return <div className="CardPayement" style={this.bgStyle}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="TitleCardPayement">{this.props.children}</div>
                            <div className="ListContainPayement">
                                <div className="ComprendPayement">
                                    Comprend
                                </div>
                                <div className="ListPayementItem">
                                    {this.props.pack.map((elm: string, i: number) =>
                                        <div key={i}>
                                            {elm}
                                        </div>)}
                                </div>
                                <div className="BuyButtonPayement">
                                    {this.props.connected ? <>
                                        <ButtonPayement
                                            price={this.props.price}
                                            event_id={this.props.event.id}
                                            user_id={this.props.user_id}
                                            external={this.props.external}
                                        />
                                    </>
                                        : <Link to="/login">
                                        Se connecter <img src={Arrow} className="ArrowPayementButton" alt="Go to payement" />
                                        </Link>
                                    }
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="PricePayement">
                                {this.props.price}€
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

interface reduxPropsButtonPayment {
    fetched_ticket: boolean,
    tickets: TicketModel[],
}

interface propsButtonPayment extends reduxPropsButtonPayment {
    price: number,
    event_id: number,
    user_id: number,
    external: boolean,
}

const PaypalStyle = {
    color: "blue",
    size: "responsive"
}

const _ButtonPayement: React.FC<propsButtonPayment> = (props: propsButtonPayment) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setShowLoading] = useState<boolean>(false);
    const [showPayment, setShowPayment] = useState<"LOADING" | "BUY" | "ALREADY_BUY">("LOADING");

    const handleError = (e: any) => {
        message.error("Erreur : Impossible de procéder au paiement")
    }

    const handleSuccess = (e: any) => {
        setShowLoading(true);
        PayementHelper.MakePayement(e, props.event_id, props.user_id)
            .then(() => {
                message.success("Transaction terminée avec succès !")
                TicketHelpers.FetchTickets();
                setShowModal(false);
                setShowLoading(false);
            })
            .catch((err: Error) => {
                message.error(err.message);
                setShowLoading(false);
            })
    }

    const handleCancel = (e: any) => {
        console.log(e);
        message.error("Erreur : Transaction annulée")
    }

    useEffect(() => {
        if (!props.fetched_ticket) {
            TicketHelpers.FetchTickets();
            return;
        }
        setShowPayment(props.tickets.reduce((acc: boolean, elm: TicketModel): boolean => {
            if (!acc)
                return false;
            return elm.event_id !== props.event_id
        }, true) ? "BUY" : "ALREADY_BUY")

    }, [props.fetched_ticket, props.tickets, props.event_id])

    const handleBuyFree = () => {
        setShowLoading(true);
        PayementHelper.MakeTicketFree(props.event_id, props.external)
            .then(() => {
                message.success("Transaction terminée avec succès");
                TicketHelpers.FetchTickets();
                setShowModal(false);
                setShowLoading(false);
            })
            .catch((err: Error) => {
                message.error(err.message);
                setShowLoading(false);
            })
    }


    return <>
        <Modal
            visible={showModal}
            title="Payer"
            onCancel={() => setShowModal(false)}
            footer={[
                <Button onClick={() => setShowModal(false)} key="Cancel">Annuler</Button>
            ]}
        >
            {(!loading) ?
                <h3>Procéder au paiement via PayPal</h3>
                :
                <Icon type="loading" />
            }
            {(showPayment === "BUY") &&
                <>
                {props.price !== 0 ?
                    <PayPal
                        client={{ sandbox: "ARz_TSAM8_SAqrxrIFGYxGo869ttHCcHQt7CCFw1tj4H0Od_hZ7fyTFe1p-9vLpb8acMtQUMaVrZFQxk", production: "ARz_TSAM8_SAqrxrIFGYxGo869ttHCcHQt7CCFw1tj4H0Od_hZ7fyTFe1p-9vLpb8acMtQUMaVrZFQxk" }}
                        currency="EUR"
                        total={props.price}
                        onError={handleError}
                        onSuccess={handleSuccess}
                        onCancel={handleCancel}
                        env={(!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? "sandbox" : "production"}
                        style={PaypalStyle}

                    />
                    : <Button type="primary" onClick={handleBuyFree}>Obtenir un ticket</Button>
                }
            </>
            }
            {(showPayment === "LOADING") && <Icon type="loading" />}
            {(showPayment === "ALREADY_BUY") && <div>Vous avez déjà acheté le ticket</div>}

        </Modal>
        <div onClick={() => setShowModal(true)}>
            Acheter <img src={Arrow} className="ArrowPayementButton" alt="Go to payement" />
        </div>
    </>
}

const mapStateToPropsButton = (state: any): reduxPropsButtonPayment => ({
    fetched_ticket: state.tickets.fetched,
    tickets: state.tickets.tickets,
})

const ButtonPayement = connect(mapStateToPropsButton)(_ButtonPayement);


const mapStateToProps = (state: any): reduxProps => ({
    connected: state.header.connected,
    user_id: state.header.id,
});

export default connect(mapStateToProps)(CardPayement);