import React from 'react';
import { EventModel } from '../../Models/Event';
import './Payement.css'
import Line from '../Layers/Line';
import CardPayement from '../Layers/CardPayement';

interface props {
    event: EventModel,
}

interface state {

}

export default class Payement extends React.Component<props, state> {
    render() {
        return <div id="Payement">
            <div className="TitlePayement">Prix</div>
            <Line />
            <div style={{height: 20}}/>
            <CardPayement
                startColor="#cc5905"
                endColor="#fa8810"
                pack={["1 ticket", "1 boisson"]}
                price={this.props.event.externPrice}
                event={this.props.event}
                external={true}
            >Externe</CardPayement>
            <CardPayement
                startColor="#10ad90"
                endColor="#0d2436"
                pack={["1 ticket", "1 boisson"]}
                price={this.props.event.internPrice}
                event={this.props.event}
                external={false}
            >Interne</CardPayement>
        </div>
    }
}