import FetchEvent from './FetchEvents';
import InitConnect from './InitConnected'
import Store from '../Redux/Store';
import { SetOfflineAction } from '../Redux/Actions/HeaderActions';
import TicketHelper from './TicketHelper';

export default class OfflineHelper {

    /**
     * Init all fetcher to try to get data from server
     */
    static async GetAll() {

        Store.dispatch(SetOfflineAction(false));
        await InitConnect();
        await FetchEvent.fetch();
        await TicketHelper.FetchTickets();

    }
}