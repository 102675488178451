import redux from 'redux';
import { EventActions, FetchPayloadAction, SetEventPayloadAction} from '../Actions/EventActions'

import { EventModel } from '../../Models/Event';
export interface state {
    events: EventModel[],
    fetched: boolean,
}

const initialState: state = {
    events: [],
    fetched: false,
}

export function EventReducer(state: state = initialState, action: redux.Action) {
    switch (action.type) {
        case EventActions.FETCH_EVENTS: {
            let actionFetch = action as FetchPayloadAction;
            let newState = {
                ...state,
                events: actionFetch.payload,
                fetched: true,
            }
            return newState;
        }
        case EventActions.SET_EVENT: {
            let actionSet = action as SetEventPayloadAction;
            let newEventList = state.events.filter((elm: EventModel) => {
                if (elm.id === actionSet.payload.id) {
                    return false;
                }
                return true;
            });
            newEventList.push(actionSet.payload);
            return {
                ...state,
                events: newEventList,
            }
        }
        default:
            return state;
    }
}