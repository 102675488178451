import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from "./Ticket.module.css";
import { TicketModel } from '../../Models/Ticket';
import TicketHelper from '../../Utils/TicketHelper';
import { Container } from 'react-bootstrap';
import { Skeleton, Empty } from 'antd';
import QRCode from 'qrcode.react';
import Footer from '../Layers/Footer';

interface ReduxProps {
    connected: boolean,
    fetched: boolean,
    tickets: TicketModel[],
    username: string,
    mail: string,
    name: string,
}

interface props extends ReduxProps {
    match: {
        params: {
            id: string,
        }
    }
}

const getTicketFromProps = (tickets: TicketModel[], id: string): TicketModel | undefined => {
    return tickets.find((elm: TicketModel) => elm.id === parseInt(id));
}

const Ticket = (props: props) => {

    const ticket = useMemo<TicketModel | undefined>(() => getTicketFromProps(props.tickets, props.match.params.id), [props.match.params.id, props.tickets]);

    useEffect(() => {
        document.body.style.backgroundColor = "rgb(250, 250, 250)";

        return () => {
            document.body.style.backgroundColor = null;
        }
    }, []);

    useEffect(() => {
        if (!props.fetched && props.connected) {
            TicketHelper.FetchTickets();
        }
    }, [props.fetched, props.connected]);

    if (!localStorage.getItem("access_token") && !props.connected) {
        return (
            <Redirect to="/login" />
        )
    }


    if (!props.fetched) {
        return <div className={styles.Body}>
            <Container className={styles.SimpleContainer}>
                <Skeleton active />
            </Container>
        </div>
    }

    if (!ticket) {
        return <div className={styles.Body}>
            <Container className={styles.SimpleContainer}>
                <Empty description="Ticket Invalide"/>
            </Container>
        </div>
    }

    return (
        <>
            <div className={styles.Body}>
                <Container>
                    <div className={"col-lg-9 " + styles.InfoUser + " " + styles.SimpleContainer}>
                        <div className={styles.TitleUser}>{props.username}</div>
                        <div>{props.name}</div>
                        <div>{props.mail}</div>
                    </div>
                    <h2 className={styles.TitleEvent}>{ticket.event_name}</h2>
                    <div className={"col-lg-9 " + styles.SimpleContainer + " " + styles.QRCodeContain}>
                        <QRCode value={TicketHelper.GetQRCodeData(ticket)} />
                    </div>
                    <div className={styles.TagCode}>{ticket.code}</div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

const mapStateToProps = (state: any) : ReduxProps => ({
    connected: state.header.connected,
    fetched: state.tickets.fetched,
    tickets: state.tickets.tickets,
    username: state.header.name,
    mail: state.header.email,
    name: state.header.firstname + " " + state.header.lastname,
})

export default connect(mapStateToProps)(Ticket);